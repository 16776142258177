<template>
    <v-app style="background-color: #bbb;">
        <HeaderContainer />

        <v-main :style="getBackground">
            <h3 v-if="loading" class="mt-6">Carregando, aguarde...</h3>
            <v-container v-else fluid class="flex-container">
                <ProductCard
                    v-for="(card, key) in dados"
                    :key="key"
                    :product="card"
                    @buy="onBuyClick"
                    class="mx-auto my-12"
                    max-width="250"
                />
            </v-container>
        </v-main>

        <v-btn
            v-if="$store.state.cart.length"
            fab
            fixed
            bottom
            right
            color="#2caa31"
            class="white--text"
            x-large
            @click="goToCart"
        >
            <v-icon>mdi-cart</v-icon>
        </v-btn>
        <v-badge
            v-if="$store.state.cart.length"
            :content="$store.state.cart.length"
            style="bottom: 75px; right: 38px; z-index: 4; position: fixed;"
        />

        <FooterContainer />
    </v-app>
</template>

<script>
import FooterContainer from '../FooterContainer';
import HeaderContainer from '../HeaderContainer';
import ProductCard from './ProductCard';

export default {
    name: 'HomePage',

    components: {
        ProductCard,
        FooterContainer,
        HeaderContainer,
    },

    data: () => ({
        loading: false,
        dados: [],
    }),

    mounted() {
        this.innit();
    },

    computed: {
        getBackground() {
            const imagem = this.$store.state?.config?.background || '';

            if (!imagem?.url) {
                return {
                    'background-color': '#ddd',
                };
            }

            const url = imagem.tmp
                ? `${this.$urlAPI}/files/tmp/${imagem.url}`
                : `${this.$urlAPI}/storage/${imagem.url}`;

            return {
                'background-image': `url(${url})`,
                'background-size': 'cover',
                'background-position': 'center',
                'background-repeat': 'no-repeat',
            }
        },
    },

    methods: {
        innit() {
            this.loading = true;
            this.dados = [];
            this.$http.get('ecommerce/produtos').then(resp => {
                this.dados = resp.data.data;
                this.loading = false;
            })
            .catch(() => {
                this.$toast.error('Não foi possivel concluir a operação');
                this.loading = false;
            });
        },

        onBuyClick(item) {
            const itemType = item?.produtos?.length ? 'pacote' : 'produto';

            this.$router.push(`ecommerce/${itemType}/${item.id}`);
        },

        goToCart() {
            this.$router.push('/ecommerce/carrinho');
        },
    },
}
</script>

<style scoped>
.flex-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    padding: 0;
    margin: 0;
    list-style: none;
}
</style>
