<template>
    <v-card class="mx-auto my-12" elevation="4" width="320" style="margin: 15px !important;">
        <v-img
            class="ma-2"
            height="180"
            :src="getImageUrl(product.imagens)"
        />

        <v-card-title style="word-break: keep-all; margin-top: -5px; font-size: 22px; font-family: Anton, Oswald, Impact, Sans, Tahoma;">{{ product.nome }}</v-card-title>
        <v-card-subtitle
            @click="buy"
            class="text-start"
            style="white-space: pre-line; margin-top: 4px; font-family: Sans, Tahoma; font-size: 16px;"
            v-html="limitText(product.descricao)"
        />

        <div v-if="+product.valor_promocional" class="pa-2">
            <div style="text-align: center; margin-top:-8px">
                <v-chip class="mb-2" color="green" text-color="white">
                    DESCONTO ESPECIAL
                </v-chip>
            </div>
            <span style="font-weight: bold;">DE</span>
            <span style="text-decoration: line-through; color: red; font-weight: bold;">
                R${{ formatPrice(product.valor_venda) }}
            </span>
            <span style="font-weight: bold;">
                POR<br>
            </span>
            <span style="color: green; font-weight: bold; font-size: 32px;">
                R${{ formatPrice(product.valor_promocional) }}
            </span>
        </div>
        <div v-else>
            <span style="font-weight: bold; text-align: left; color: green; font-size: 36px; margin-bottom: 3px;">
                R$ {{ formatPrice(product.valor_venda) }}
            </span>
        </div>

        <v-card-actions>
            <v-btn
                class="white--text"
                color="green"
                @click="buy"
                block
            >
                <v-icon class="mr-1">mdi-cart-arrow-down</v-icon>
                Comprar
            </v-btn>
        </v-card-actions>
        <v-card-actions>
            <v-btn
                class="white--text"
                color="blue"
                @click="$router.push('/ecommerce/carrinho')"
                block
            >
                <v-icon class="mr-1">mdi-cart</v-icon>
                Ver Carrinho
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import imageMixin from '@/mixins/image';

export default {
    name: 'ProductCard',

    mixins: [ imageMixin ],

    props: {
        product: {
            type: Object,
            default: Object,
        },
    },

    data: () => ({
        loading: false,
        dados: [],
    }),

    methods: {
        buy() {
            this.$emit('buy', this.product);
        },

        limitText(text) {
            const limit = 128;
            return text.length > limit ? text.substring(0, limit) + '... <br><b><a>ver detalhes</a></b>' : text;
        }
    },
}
</script>

<style scoped>
</style>
